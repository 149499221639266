// // Location Autocomplete
// .locationField {
//   position: relative;
// }

.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 5px);
  box-shadow: 0 0 3px rgba($black, 0.1);
  z-index: 999;
  color: $body-color;
  border-radius: $border-radius;
  overflow: hidden;


  .suggestion-item,
  .suggestion-item--active {
    padding: 0.5rem 1rem;
    background-color: $light;
    cursor: pointer;
  }

  .suggestion-item--active {
    background-color: $dark;
    color: $white;
  }
}

.google-map {
  width: 100%;
  height: 33vh;
}
