.messages-layout {
  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
  }
  
}

.chatList {
  height: auto;
  background-color: $gray-100;
  border: 0;
  @include media-breakpoint-down(sm) {
    margin-top: 5px;
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 80px);
  }
  .search {
    border-radius: 15px 15px !important;
    // background-color: rgb(255, 255, 255) !important;
    border: 1px solid $gray-800 !important;
  }

  .avatar-wrapper {
    position: relative;
  }

  .search:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  .list-group-item {
    display: block;
    color: $dark;
    border: 0;
    border-left: 0.5rem solid transparent;
    background-color: transparent;
    border-radius: 0;
    padding: 0.5rem;
    @include media-breakpoint-up(xl) {
      padding: 0.75rem 1rem;
    }
    .user-info {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 5px;
      @include media-breakpoint-up(lg) {
        margin-left: 10px;
      }
    }
    
    &.active {
      background-color: rgba($cyan, 0.2);
      border-color: $cyan;
      font-weight: $font-weight-bold;
      color: $teal;
    }
  }
  .notification-number {
    position: absolute;
    right: 0;
    top: -4px;
  }
}

.avatar-wrapper {
  width: 36px;
  min-width: 36px;
  max-width: 100%;
  &.avatar-lg {
    width: 80px;
  }
  .avatar {
    position: relative;
    height: 0;
    padding-top: calc(100% - 4px);   
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 2px solid $cyan;
    background-image: url('/common/assets/icons/shared-earth-profile-icon-light.svg');
  }
  &.avatar-has-space {
    .avatar {
      border: 2px solid $cyan;
    }
  }
  &.avatar-needs-space {
    .avatar {
      border: 2px solid $primary;
    }
  }
}

.chatWindow {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 502;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-breakpoint-up(md) {
    width: calc(100% - 50px);
    left: 50px;
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    height: calc(100vh - 100px);
    width: 100%;
    z-index: 500;
    left: auto;
  }
  .chatMessages {
    // .bubble-guest, .bubble-owner  {
    //    TODO: Add shared code
    // }
    .bubble-owner {
      margin-top: 20px;
      margin-bottom: 20px;
      &:before {
        content: attr(data-date);
        position: absolute;
        right: 15px;
        bottom: -0.8em;
        display: block;
        font-size: 0.75rem;
        color: $primary;
      }
      a {
        background: $white;
        color: $black !important;
        border: 1px solid $gray-500;
        border-radius: 12px 12px 0 12px;
        display: block;
        margin-left: auto;
        margin-right: 0;
        padding: 7px 13px 7px 13px;
        text-decoration: none;
        @include media-breakpoint-up(sm) {
          max-width: 50%;
        }
      }
    }

    .bubble-guest {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0;
      @include media-breakpoint-up(sm) {
        max-width: 50%;
      }
      &:before {
        content: attr(data-date);
        position: absolute;
        left: 15px;
        bottom: -0.8em;
        display: block;
        font-size: 0.75rem;
        color: $secondary;
      }
      a {
        background: $gray-500;
        color: $white !important;
        border: 1px solid $gray-500;
        border-radius: 12px 12px 12px 0;
        display: block;
        padding: 7px 13px 7px 13px;
        text-decoration: none;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.chatMessages {
  padding-top: 50px;
  padding-bottom: 140px;
}

#chatBox {
  position: fixed;
  bottom: 0;
  left: auto;
  right: 0;
  width: 100%;
  background: $white;
  border-top: 1px solid $mid;
  padding: 15px;
  z-index: 550;
  @include media-breakpoint-up(md) {
    bottom: 0;
    padding: 20px;
    width: calc(100% - 50px);
  }
  @include media-breakpoint-up(lg) {
    bottom: 0;
    width: calc(75% - 15px);
  }
  @include media-breakpoint-up(xl) {
    bottom: 0;
    width: calc(75% - 201px);
    padding: 30px 40px;
  }
  textarea {
    &:active,
    &:focus,
    &:focus-visible {
      outline: 0;
      border-radius: 15px;
      border: 2px solid $blue-light;
      box-shadow: 0 0 2px 1px $blue-light;
      font-size: 1rem;
    }
  }
  .btn {
    max-height: 40px;
    margin-left: 5px;
  }
}

.notification-number {
  width: 15px;
  height: 15px;
  background-color: $red;
  color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: 100;
  i {
    font-size: 9px;
  }
}

.chat-map {
  display: block;
  &.active {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: none;
    &.active {
      display: block;
    }
  }
}

.user-info-wrapper {
  @include media-breakpoint-down(md) {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
  }
  @include media-breakpoint-down(md) {
    padding-top: 50px !important;
  }
  
  .close-conversation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.user-fallback {
  display: none;
  line-height: 1;
}

.chatFocus {
  // .user-info-wrapper {
  //   .close-conversation {
  //     top: 297px;
  //   }
  // }
  .user-fallback {
    display: block;
  }
}

.user-info-header-name {
  display: block;
  margin-top: -22px;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.chat-map-trigger {
  display: none;
  
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
  }
  i {
    @include transition(0.4s);
    transform: rotate(0)
  }
  &.active {
    i {
      transform: rotate(-180deg);
    }
  }

  
  @include media-breakpoint-up(lg) {
    i {
      transform: rotate(-180deg);
    }
    &.active {
      i {
        transform: rotate(0)
      }
    }
  }
}