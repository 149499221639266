.icon-custom {
  display: inline-block;
  &::before {
    content: "";
    width: 2rem;
    height: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
  }
  &.icon-map {
    &::before {
      background-image: url("/common/assets/icons/icon-map.svg");
    }
  }
  &.icon-map-white {
    &::before {
      background-image: url("/common/assets/icons/icon-map-white.svg");
    }
  }
  &.icon-list {
    &::before {
      background-image: url("/common/assets/icons/icon-list.svg");
    }
  }
  &.icon-list-white {
    &::before {
      background-image: url("/common/assets/icons/icon-list-white.svg");
    }
  }
  &.icon-messages {
    &::before {
      background-image: url("/common/assets/icons/icon-messages.svg");
    }
  }
  &.icon-messages-white {
    &::before {
      background-image: url("/common/assets/icons/icon-messages-white.svg");
    }
  }
  &.icon-resources {
    &::before {
      background-image: url("/common/assets/icons/icon-resources.svg");
    }
  }
  &.icon-resources-white {
    &::before {
      background-image: url("/common/assets/icons/icon-resources-white.svg");
    }
  }
  &.icon-donate {
    &::before {
      background-image: url("/common/assets/icons/icon-donate.svg");
    }
  }
  &.icon-hamburger {
    &::before {
      background-image: url("/common/assets/icons/icon-hamburger.svg");
    }
  }
  &.icon-donate-white {
    &::before {
      background-image: url("/common/assets/icons/icon-donate-white.svg");
    }
  }
  &.icon-merchandise {
    &::before {
      background-image: url("/common/assets/icons/icon-merchandise.svg");
    }
  }
  &.icon-merchandise-white {
    &::before {
      background-image: url("/common/assets/icons/icon-merchandise-white.svg");
    }
  }
  &.icon-profile {
    &::before {
      background-image: url("/common/assets/icons/icon-profile.svg");
    }
  }
  &.icon-profile-white {
    &::before {
      background-image: url("/common/assets/icons/icon-profile-white.svg");
    }
  }
  &.icon-settings {
    &::before {
      background-image: url("/common/assets/icons/icon-settings.svg");
    }
  }
  &.icon-settings-white {
    &::before {
      background-image: url("/common/assets/icons/icon-settings-white.svg");
    }
  }
}

.icon-location {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  z-index: 100;
  &.icon-lg {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.icon-xl {
    height: 3rem;
    width: 3rem;
  }
  &.icon-xxl {
    height: 4rem;
    width: 4rem;
  }
  &.icon-map {
    width: 3rem;
    height: 3rem;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.62);
  }

  &.icon-need {
    background-color: $need;
    background-image: url("/common/assets/icons/map-want-icon.svg");
    &.icon-owner {
      box-shadow: 0 0 0 6px rgba($need, 0.65);
    }
  }
  &.icon-have {
    background-color: $have;
    background-image: url("/common/assets/icons/map-have-icon.svg");
    &.icon-owner {
      box-shadow: 0 0 0 6px rgba($have, 0.65);
    }
  }
  &.icon-color-only {
    background-image: none !important;
  }
}

.icon-need {
  background-color: $need;
  background-image: url("/common/assets/icons/map-want-icon.svg");
}
.icon-have {
  background-color: $have;
  background-image: url("/common/assets/icons/map-have-icon.svg");
}
