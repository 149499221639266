.primaryMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: $gray-900;
  &::-webkit-scrollbar {
    display: none;
  }
  .img-avatar {
    // width: 8rem;
    // height: 8rem;
    border-radius: 100%;
    border: 4px solid $primary;
  }
  .nav-link {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.36px;
    text-transform: capitalize;
    color: $white;
    padding: 0 4.7rem;
    margin: 0;
    background-color: transparent;
    white-space: nowrap;
    @include transition(0.5s);
    position: relative;
    &:hover,
    &.active {
      background-color: $gray-800;
      border-color: $white;
      &::before {
        opacity: 1;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: $white;
      opacity: 0;
      @include transition(0.5s);
    }
    @include media-breakpoint-down(lg) {
      padding: 0;
      span {
        opacity: 0;
      }
    }
    &.active {
      font-weight: $font-weight-bold;
    }
    .icon-custom {
      &::before {
        width: 3rem;
        height: 3rem;
      }
    }
    span {
      display: inline-block;
    }
  }
}

#mobile-homenav {
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 54px;
    width: 100%;
    height: 100%;
    background: $white;
    left: -100%;
    @include transition(left, 0.4s);
    .nav-link {
      display: flex;
      align-items: center;
    }
    .btn {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      @include media-breakpoint-down(md) {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
      }
    }
    .fas {
      font-size: 1rem;
      color: #66696b;
      padding-left: 8px;
      padding-right: 8px;
    }
    
  }
  &.show {
    
    left: 0;
    transition: left 0.4s ease-in-out;
  }
  .footer-mobile-nav {
    bottom: 54px;
  }
}

#mobile-home-auth-buttons {
  width: 200px;
}

#userNav {
  margin-bottom: 15px;
}

#mobileNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
}

#toggle-mobile-app-nav {
  margin-top: 4px;
  .icon-custom {
    &::before {
      width: 3rem;
      height: 3rem;
    }
  }
  
}

.mobile-app-nav {
  width: 100%;
  z-index: 999;
  font-size: 1.3rem;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  .icon-custom {
    &::before {
      width: 3rem;
      height: 3rem;
    }
  }
  .nav-link {
    border-bottom: 4px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 0;
    &.active {
      background-color: $light;
      border-bottom: 4px solid $teal;
    }
  }
}

.mobile-header {
  width: 100%;
  height: 51px;
  border-bottom: 1px solid $gray-500;
  display: flex;
  align-items: center;
  .nav-logo {
    // position: absolute;
    // top: 50%;
    // left: 15px;
    // transform: translateY(-50%);
  }
  img {
    max-width: 145px;
  }
}

.mobile-nav-drawer {
  position: fixed;
  top: 103px;
  left: -100%;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  z-index: 499;
  background-color: $white;
  
  
  .nav-link {
    color: $body-color;
    display: flex;
    align-items: center;
  }
  @include transition(0.4s);
  &.expanded {
    left: 0;
    @include media-breakpoint-up(lg) {
      left: -100%;
    }
  }
  
}

.mobile-secondary-nav {
  .nav-link {
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    padding: 1rem;
    color: $body-color;
  }
}

.footer-mobile-nav {
  padding: 1.2rem;
  background-color: $dark;
  position: absolute;
  display: flex;
  bottom: 100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 110px;
  }
}
