.modal-full-width-sm {
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .modal-dialog {
      margin: 0 auto;
      max-width: 100%;
      height: 100%;
      background: $white;
    }
    .modal-header {
      border-radius: 0;
    }
    .modal-content {
      max-width: 100%;
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .modal-body {
      border-radius: 0;
    }
  }
}

.modal {
  .lead {
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  .modal-header {
    
    @include media-breakpoint-down(sm) {
      display: block;
      padding: 0;
      border-bottom: 0;
      .modal-title {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .close-custom {
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      text-align: left;
      top: auto;
      right: auto;
      left: auto;
      display: block;
      background: $light;
      color: $body-color;
      font-size: 1rem;
      margin: 0;
      float: none;
    }
  }
  .carousel {
    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .carousel-inner {
        padding-bottom: 25px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        display: block;
        bottom: 10px;
        top: auto;
        
      }
      .carousel-control-prev {
        left: 0;
      }
      .carousel-control-next {
        right: 0;
      }
      
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        &::before {
          padding: 6px;
        }
      }
    }
    .carousel-indicators {
      li {
        border: 2px solid $dark;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 7px;
        height: 7px;
        &.active {
          background-color: $dark;
          border: 2px solid $dark;
        }
      }
    }
  }
}

.auth-modal {
  .modal-header {
    padding: 1rem 1rem;
  }
  .close {
    @include media-breakpoint-down(sm) {
      left: 15px;
      right: auto;
    }
  }
}

.auth-buttons {
  .btn {
    div {
      @include media-breakpoint-down(md) {
        width: 110px;
        text-align: left;
        .icon-wrapper {
          width: 40px;
          display: inline-block;
          text-align: center;
          i {
            margin-right: 0;
          }
        }
      }
    }
  }
}

#modal-donate {
  .modal-header {
    @include media-breakpoint-up(md) {
      padding: 3rem;
      background-color: $light;
    }
  }

}

#modal-welcome {
  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
  .modal-header {
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
    @include media-breakpoint-down(sm) {
      background-color: $light;
      padding: 1rem;
      h2 {
        color: $body-color;
      }
    }
  }
  .item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 325px;
    @include media-breakpoint-up(md) {
      height: 400px;
    }
    @include media-breakpoint-up(lg) {
      height: 550px;
    }
    @include media-breakpoint-down(md) {
      .h1 {
        font-size: 1.5rem;
      }
    }
  }
}

.welcome-icons {
  @include media-breakpoint-down(md) {
    img {
      width: 100px;
    }
  }
}