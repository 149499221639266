.list-layout {
  padding-bottom: 50px;
  padding-top: 175px;
  
  @include media-breakpoint-up(md) {
    padding-top: 130px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 192px;
  }

  .filter-wrapper {
    background: $white;
    @include media-breakpoint-up(md) {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
  .filters {
    @include media-breakpoint-down(sm) {
      box-shadow: 1px 1px 3px rgba($black, 0.3);
    }
  }
  .btn-connect {
    display: block;
    width: 100%;
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
  .avatar-wrapper {
    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
  }
}

.list-header {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}



.col-distance {
  @include media-breakpoint-down(sm) {
    margin-top: -40px;
  }
}

.img-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  border: 4px solid $primary;
}

.filter-trigger {
  display: none;
}
