//buttons
.btn {
  font-weight: 600;
  @include media-breakpoint-down(md) {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  &.disabled {
    opacity: 0.4;
  }
  &.btn-icon {
    display: flex;
    i {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
  }
  &.btn-save-header {
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 5px;
      right: 5%;
      z-index: 375;
    }
    @include media-breakpoint-up(lg) {
      top: 20px;
    }
  }
  &.btn-lg {
    @include media-breakpoint-down(md) {
      font-size: 1rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
  }
}
.btn[class*="btn-outline-"] {
  &:hover,
  &:active {
    color: $white;
  }
}
.btn:not([class*="btn-outline-"]) {
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
}


a {
  &.disabled {
    opacity: 0.4;
    cursor:default;
  }
}

//carousels
.carousel-control-next {
  right: -15%;
  .carousel-control-next-icon {
    background-image: none;
    &::before {
      content: "";
      border: solid $black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 10px;
      transform: rotate(-45deg);
    }
  }
}

.carousel-control-prev {
  left: -15%;
  .carousel-control-prev-icon {
    background-image: none;
    &::before {
      content: "";
      border: solid $black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 10px;
      transform: rotate(135deg);
    }
  }
}

.carousel-indicators {
  li {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid $gray-600;
    border-radius: 50%;
    &.active {
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
}

//close button
.close {
  position: absolute;
  right: 15px;
  top: 10px;
  border: 0;
  background: transparent;
  font-size: 2rem;
  font-weight: 100;
  text-shadow: none;
  z-index: 100;
}

.card {
  border: 0;
  .btn-close {
    background-color: $white;
    border-radius: 50%;
    transition: 0.2s;
    opacity: 1;
    border: 1px solid $body-color;
    width: 25px;
    height: 25px;
    color: $body-color;
    z-index: 500;
    position: absolute;
    top: -21px;
    right: -21px;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.modal {
  .carousel-control-prev {
    left: -25px;
    @include media-breakpoint-down(md) {
      left: -15px;
    }
  }
  .carousel-control-next {
    right: -25px;
    @include media-breakpoint-down(md) {
      right: -15px;
    }
  }
  .map-input-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    .rounded {
      border-radius: 0 !important;
    }
  }
}

.carousel-control-prev {
  @include media-breakpoint-down(sm) {
    left: 0;
  }
}

.carousel-control-next {
  @include media-breakpoint-down(sm) {
    right: 0;
  }
}

// Alerts
.alert {
  margin: 0;
  border-radius: 0;
  color: $white;
  &.alert-light {
    color: $dark;
  }
  &.alert-global {
    padding: 1rem 4rem 1rem 0;
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 665;
    top: 0;
    @include media-breakpoint-up(md) {
      width: calc(100% - 50px);
      margin-left: 50px;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 300px;
      width: calc(100% - 300px);
    }
    &.notification-home,
    &.notification-privacy-policy {
      width: 100%;
      margin-left: 0;
      .container {
        margin-left: auto;
      }
    }
    .container {
      @include media-breakpoint-up(lg) {
        margin-left: 20px;
      }
    }
  }
}

.input-group {
  > .form-control {
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
}
