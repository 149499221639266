.pageHeader {
  padding-bottom: 0;
  height: 100%;
  box-shadow: 1px 1px 3px rgba($black, 0.3);
  z-index: 999;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $gray-900;
  &::-webkit-scrollbar {
    display: none;
  }

  @include transition(0.5s);
  .avatar-wrapper {
    width: 120px;
  }
  .footer-logo-wrapper {
    background-color: $gray-800;
  }
  .nav-logo {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $gray-900;
    z-index: 98;
    width: 0;
    pointer-events: none;
  }
  @include media-breakpoint-up(xl) {
    .nav-logo {
      width: 300px;
      pointer-events: all;
      @include animation(fadeIn, 0.5s, 0.4s);
    }
    #primaryNav {
      padding-top: 160px;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 50px;
    #primaryNav {
      padding-top: 50px;
    }
    .nav-logo {
      opacity: 0;
      z-index: 0;
      @include transition(opacity, 0.6s);
    }
    .footer-logo-wrapper {
      background-color: $gray-900;
    }
    &.expanded {
      width: 300px;
      .nav-logo {
        opacity: 1;
        width: 300px;
        z-index: 98;
        pointer-events: all;
        @include transition(opacity, 0.6s);
      }
      #primaryNav {
        padding-top: 160px;
      }
      .nav-logo,
      .nav-footer-logo {
        @include animation(fadeIn, 0.5s, 0.5s);
      }

      .nav-link {
        span {
          @include animation(fadeIn, 0.5s, 0.5s);
        }
      }
      .nav-trigger {
        transform: rotate(180deg);
      }
      .primaryMenu {
        overflow-y: auto;
      }
      .footer-logo-wrapper {
        background-color: $gray-800;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .nav-logo,
    .nav-footer-logo {
      opacity: 0;
    }
    .nav-logo {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .avatar-wrapper {
      width: 60px;
    }
  }
}

.navbar-collapse.collapsing {
  @include transition(right, 0.4s);
  right: -100%;
}
.navbar-collapse.show {
  right: 0;
  transition: right 0.4s ease-in-out;
}

.nav-trigger {
  position: absolute;
  top: 10px;
  right: 13px;
  background: transparent;
  border: 0;
  display: block;
  z-index: 100;
  @include media-breakpoint-up(xl) {
    display: none;
  }
  @include transition(0.4s);
  i {
    color: $white;
    font-size: 1.1rem;
  }
}

.pageContent {
  &.expanded {
    margin-left: 300px;
    @include transition(0.5s);
  }
  padding-top: 100px;
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
    width: calc(100% - 50px);
    margin-left: 50px;
  }
  @include media-breakpoint-up(lg) {
    
    @include transition(0.6s);
    &.expanded {
      margin-left: 300px;
      width: calc(100% - 300px);
      header {
        @include transition(width, 0.6s);
        width: calc(100% - 300px);
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-left: 300px;
    width: calc(100% - 300px);
  }
  @include transition;
  overflow: hidden;

  &.sidebar-toggle {
    width: 100%;
    position: fixed;
    @include transition;

    .overlay {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10;
      @include transition;

      &.sidebar-toggle {
        visibility: visible;
        opacity: 1;
        @include transition;
      }

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }
  .container {
    @include media-breakpoint-up(lg) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  .row {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 350;
    @include media-breakpoint-up(md) {
      position: fixed;
      width: calc(100% - 50px);
    }
    @include media-breakpoint-up(xl) {
      width: calc(100% - 300px);
    }
  }
}

.pageBanner {
  background-color: $primary;
  padding: 1rem 0;
  height: 51px;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(lg) {
    padding: 1.5rem 0;
    height: auto;
    display: block;
  }
  h1 {
    color: $white;
    font-size: 0.8rem;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.7rem;
    } 
  }
}

.pageFooter {
}

.open-notification {
  padding-top: 100px;
  .pageContent {
    header {
      top: 100px;
    }
  }

  &.homepage-layout {
    padding-top: 155px;
    // @include media-breakpoint-up(md) {
    //   &.logged-in {
    //     padding-top: 285px;
    //   }
    // }
  }

  #homeHeader{
    top: 100px;
  }
  .btn {
    &.btn-save-header {
      top: 120px;
    }
  }
}

.nav-default {
  padding-top: 55px;
  @include media-breakpoint-up(lg) {
    padding-top: 94px;
  }
  header {
    box-shadow: 1px 2px 3px rgba($black, 0.1);
  }
}
