.redux-toastr {
  .toastr.rrt-success {
    background-color: $green-dark;
  }
  .toastr.rtt-error {
    background-color: $dark;
  }
  .close-toastr {
    span {
      color: $white;
    }
  }
}
