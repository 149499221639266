.react-select-container {
  .react-select__control {
    border-color: $mid;
    &:hover {
      border-color: $mid;
      border-width: 1px;
    }
  }
  .react-select__control--is-focused {
    border-color: $mid;
    box-shadow: none;
  }
  .react-select__input {
    padding: 3px;
  }
  .react-select__single-value {
    color: $dark;
  }
  .react-select__option {
    &:hover {
      background-color: $mid;
      color: $dark;
      font-weight: bold;
    }
    &.react-select__option--is-focused {
      background-color: rgba($light, 0.5);
    }
    &.react-select__option--is-selected {
      background-color: rgba($light, 1);
      color: $black;
    }
  }

}
// // React Select
// // https://react-select.com/styles
// .react-select-container {
//   background:red;
//   .react-select__control {
//     font-weight: $font-weight-bold;
//     color: $gray-400;
//     // height: 40px;
//     border: 2px solid $gray-100;
//     border-radius: 2px;
//     box-sizing: border-box;
//     @include transition;

//     .react-select__placeholder {
//       font-size: 0.8125rem;
//       color: $gray-300;
//       padding-left: 3px;
//     }

//     .react-select__indicator {
//       height: 100%;
//       display: flex;
//       align-items: center;
//     }

//     .react-select__clear-indicator {
//       background-color: $white;
//       @include transition;
//       svg {
//         fill: $gray-200 !important;
//         @include transition;
//       }
//     }

//     .react-select__indicator-separator {
//       display: none;
//     }

//     .react-select__dropdown-indicator {
//       background-color: $gray-100;
//       @include transition;

//       svg {
//         fill: $gray-400 !important;
//         @include transition;
//       }
//     }

//     &:hover {
//       background-color: red;
//       border: 2px solid $gray-100;
//       margin: 0;
//       @include transition;

//       .react-select__clear-indicator {
//         background-color: $gray-100;
//         @include transition;
//         svg {
//           fill: $gray-300 !important;
//           @include transition;
//         }
//       }

//       .react-select__dropdown-indicator {
//         background-color: $gray-100;
//         svg {
//           fill: $white !important;
//           @include transition;
//         }
//       }
//     }

//     &.react-select__control--is-focused {
//       background-color: $gray-100;
//       border: solid 2px $gray-100;
//       border-right: 2px solid $gray-100;
//       box-shadow: none;

//       .react-select__clear-indicator {
//         background-color: $gray-100;
//         svg {
//           fill: $gray-300 !important;
//         }
//       }

//       .react-select__dropdown-indicator {
//         background-color: $gray-100;
//         svg {
//           fill: $white !important;
//           @include transition;
//         }
//       }
//     }
//   }

//   .react-select__input {
//     padding: 3px;
//   }

//   .react-select__single-value {
//     color: $gray-400;
//     margin-left: 4px;
//     line-height: 1.1;
//   }

//   .react-select__menu {
//     border-radius: 5px;
//     box-shadow: 0 0 10px 0 $gray-200;

//     .react-select__menu-list {
//       padding: 0;
//     }
//   }

//   .react-select__option {
//     color: $gray-400;
//     cursor: pointer;
//   }
// }

// Create Select
// https://react-select.com/styles
// .react-select-container {
//   .react-select__control {
//     .react-select__value-container--is-multi {
//       .react-select__multi-value {
//         font-size: 0.75rem;
//         font-weight: $font-weight-bold;
//         color: $white;
//         line-height: 1.33;
//         letter-spacing: 0.36px;
//         border-radius: 14px !important;
//         text-transform: uppercase;
//         padding: 2px 5px;
//         height: auto;
//       }
//       .react-select__multi-value__remove {
//         border-radius: 50px;
//         width: 15px;
//         height: 15px;
//         padding: 1px;
//         position: relative;
//         top: 2px;

//         &:hover {
//           background-color: $danger;
//           color: $white;
//         }
//       }
//     }

//     .react-select__input {
//       color: $black;
//     }

//     //   .is-focused.has-value.Select--single {
//     // .Select-control {
//     //   .Select-value {
//     //     position: relative;
//     //     display: inline-block;
//     //   }

//     //   .Select-input {
//     //     display: inline-block;
//     //     vertical-align: top;
//     //     padding-left: 0px;
//     //   }
//     // }
//   }
// }
