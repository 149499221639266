.SlideWrapper {
  position: fixed;
  .SlideModal {
    background-color: $white;
    //this declaration is for firefox, for some reason wasn't making rows take up 100% width
    .row {
      width: 100%;
      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    @include media-breakpoint-down(md) {
      width: 80%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      .btn {
        width: 100%;
        display: block;
        text-align: center;
        .icon-custom {
          &::before {
            width: 3rem;
            background-size: 100%;
          }
        }
      }
    }
    .close {
      position: relative;
      width: 100%;
      background-color: $light;
      text-align: left;
      left: auto;
      right: auto;
      top: auto;
      display: flex;
      align-items: center;
      font-size: 1rem;
      padding: 15px;
      float: none;
      i {
        font-size: 1.3rem;
        color: $dark;
        opacity: 1;
      }
    }
  }
}