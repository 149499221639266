// .auth-container {
//   min-height: 500px;
//   --container-height: 100vh;
// }

// .amplify-authenticator {
//   background: red;
//   min-height: 500px;
// }
amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 50vh;
  // min-height: 400px;
}