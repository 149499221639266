.range-slider__wrap {
  background: transparent;
}
.donate-total {
  input {
    font-family: "Merriweather";
    position: relative;
    font-size: 2rem;
    padding: 0px;
    height: auto;
    margin-top: -0.25rem;
    border: 0px;
    border-radius: 0px;
    text-align: center;
    &:focus {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0);
    }
  }
  &::before {
    content: "$";
    font-family: "Merriweather";
    opacity: 0.5;
    font-size: 1rem;
    position: absolute;
    left: -.25rem;
    height: 2rem;
    width: 0.5rem;
    z-index: 1;
  }
}

.btn-apple {
  background: $black;
}
