.homepage-layout {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
  section {
    padding: 50px 0;
    @include media-breakpoint-up(lg) {
      padding: 100px 0;
    }
    
  }
  // &.logged-in {
  //   padding-top: 103px;
  //   @include media-breakpoint-up(lg) {
  //     padding-top: 160px;
  //   }
    
  // }
}

#homeIntro {
  background-image: url(../../common/assets/images/iStock_000012511813_Medium_edited02.jpg);
  @include media-breakpoint-up(lg) {
    background-image: url(../../common/assets/images/SE-marketing-page-1.jpg);
  }
  
  .form-control {
    @include media-breakpoint-down(md) {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }
  input {
    @include media-breakpoint-down(md) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-size: 1rem;
    }
  }
  .lead {
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  img {
    @include media-breakpoint-down(md) {
      padding: 0 10px;
      width: 150px;
      max-width: 100%;
    }
  }
  .no-gutters-sm {
    @include media-breakpoint-down(md) {
      margin: 0 10px;
    }
  }
}

.row-found-locations {
  @include media-breakpoint-down(sm){
    .col-12 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.no-gutters-sm {
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}


.play-video {
  font-size: 6rem;
  @include media-breakpoint-up(md) {
    font-size: 8rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 10rem;
  }
  
}

#sign-up {
  padding-bottom: 200px;
  background-image: url(../../common/assets/illustrations/SE-cityskyline-illustration.svg);
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
  
}

.input-group {
  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

#help {
  img {
    width: 125px;
    margin-bottom: 0.3rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.2rem;
    }
    
  }
}

#address-input-form {
  input {
    border-radius: $border-radius 0 0 $border-radius;
  }
}

#carousel-section {
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
    padding-top: 0;
    .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .carousel-inner {
      .carousel-item {
        float: none;
        transform: none;
        display: block;
        text-align: center;
        h4 {
          font-size: 1.6rem;
          font-family: $font-family-serif;
        }
        .btn {
          display: block;
          width: 90%;
          margin: 0 auto;
        }
        .bg-image {
          background-size: cover;
        }
        .bg-light {
          background: $white !important;
        }
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
    .carousel-indicators {
      display: none;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      &::before {
        padding: 6px;
      }
    }
    .bg-image {
      &.aspect-1x1 {
        padding-top: 75%;
      }
    }
  }
}

#presented-by {
  @include media-breakpoint-down(md) {
    img {
      max-width: 225px;
    }
  }
}