.profile-layout,
.settings-layout {
  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }
}

.location-default-text {
  left: 0;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  &:nth-of-type(1) {
    margin-top: -10px;
    margin-bottom: 5px;
  }
  &:nth-of-type(2) {
    margin-bottom: 10px;
  }
  p {
    small {
      font-weight: 800;
    }
  }
}

.location-card {
  .card-body {
    padding-top: 2rem;
  }
}

.SlideWrapper {
  .card {
    margin-top: 2rem;
  }
}

#cta-connect {
  background-color: $light;
}

.save-wrapper {
  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: $blue-light;
    z-index: 250;
    @include transition(0.4s);
    &.active {
      bottom: 0;
    }
    .btn {
      width: 100%;
      color: $blue-light !important;
    }
  }
}

