.pagination {
  padding: 30px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  li {
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: 800;
    font-size: 0.8rem;
    @include media-breakpoint-up(md) {
      margin: 0 5px;
      padding: 5px 12px;
      font-size: 1rem;
    }
    &:hover {
      background-color: $light;
    }
    &.active {
      background-color: $blue-light;
      color: $white;
    }
    a {
      font-family: $font-family-base;
    }
  }
  .previous,
  .next {
    width: 28px;
    height: 28px;
    position: relative;
    @include media-breakpoint-up(md) {
      width: 32px;
      height: 32px;
    }
    a {
      padding: 10px;
    }
    &::before {
      position: absolute;
      pointer-events: none;
    }
  }
  .previous {
    @include media-breakpoint-up(md) {
      margin-right: 10px;
    }
    &::before {
      left: 9px;
      top: 8px;
      @include media-breakpoint-up(md) {
        left: 8px;
        top: 10px;
      }
    }
  }
  .next {
    @include media-breakpoint-up(md) {
      margin-left: 10px;
    }
    &::before {
      right: 9px;
      top: 8px;
      @include media-breakpoint-up(md) {
        right: 10px;
        top: 8px;
      }
    }
  }
}