.listing-type {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  white-space: nowrap;
  background-color: $light;
  border-radius: 1rem;
  cursor: pointer;
  &:first-of-type {
    margin-left: 0;
  }
  &.need {
    &.selected {
      background-color: rgba($need, 0.1);
      box-shadow: 0 0 5px rgba($need, 1);
    }
  }
  &.have {
    &.selected {
      background-color: rgba($have, 0.1);
      box-shadow: 0 0 5px rgba($have, 1);
    }
  }
}

.input-icon {
  padding-left: 30px;
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    font-size: 1.1rem;
    color: $gray-450;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;

  }
  &.input-password {
    &::before {
      content: '\f023';
    }
  }
  &.input-email {
    &::before {
      content: '\f2bd';
    }
  }
  
}




