#homeHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: $white;
  .container {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    .container {
      max-width: 100%;
    }
  }
  .navbar-brand {
    width: 200px;
    max-width: 75%;
    @include media-breakpoint-up(xl) {
      width: 300px;
    }
  }
  .nav-item {
    margin-top: 5px;
    margin-bottom: 5px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
    }
    &:last-of-type {
      // margin-top: 20px;
      // padding-right: 15px;
      img {
        width: 137px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 0;
      }
    }
    .nav-link {
      text-transform: uppercase;
      margin-left: 5px;
      margin-right: 5px;
    }
    .btn {
      @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .link-login {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
  }
}

/* MOBLIE TOGGLE BUTTON */

.navbar-toggler {
  margin-right: 0;
  border: 0;
  margin-bottom: -5px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  .navbar-toggler-icon {
    background-image: none;
    display: inline-block;
    cursor: pointer;
    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 3px;
      background-color: $dark;
      margin: 5px 0;
      transition: 0.4s;
    }
    .bar1 {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      transform: rotate(45deg) translate(-6px, -6px);
    }
  }
  &.collapsed {
    .navbar-toggler-icon {
      .bar1 {
        transform: none;
      }
      .bar2 {
        opacity: 1;
      }
      .bar3 {
        transform: none;
      }
    }
  }
}