$loadingSize: 100px;
@keyframes spinning-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(40px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(40px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes spinning {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.spinning div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 30px;
  left: 10px;
}
.spinning div:nth-child(1) {
  @extend .icon-need;
  animation: spinning 1.923076923076923s linear infinite;
  animation-delay: -0.9615384615384615s;
  box-shadow: 2px 2px 7px rgba($dark, 0.4);
}
.spinning div:nth-child(2) {
  @extend .icon-have;
  animation: spinning 1.923076923076923s linear infinite;
  animation-delay: 0s;
  box-shadow: 2px 2px 7px rgba($dark, 0.4);
}
.spinning div:nth-child(3) {
  @extend .icon-need;
  animation: spinning-o 1.923076923076923s linear infinite;
  animation-delay: -0.9615384615384615s;
  box-shadow: 2px 2px 7px rgba($dark, 0.4);
}
.loading-indicator {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.spinning {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinning div {
  box-sizing: content-box;
}
