.map-layout {
  @include media-breakpoint-up(md) {
    padding-top: 90px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 133px;
  }
  @include media-breakpoint-down(sm) {
    .filter-wrapper {
      background: transparent;
      box-shadow: none;
    }
  }
}
.gLupvi {
  width: 100% !important;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  @include media-breakpoint-up(md) {
    height: calc(100vh - 75px);
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 133px);
  }
  @include media-breakpoint-up(xl) {
    height: calc(100vh - 133px);
  }
}

.temporary-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .btn {
    margin: 5px;
  }
}
