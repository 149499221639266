.modal-video {
  @include media-breakpoint-down(sm) {
    width: 100%;
    .modal-dialog {
      margin: 0 auto;
    }
    .modal-body {
      padding: 0;
    }
    .modal-content {
      background: transparent;
      padding: 0;
      border: 0;
    }
    .modal-header {
      background: transparent;
     
      .close {
        background: $white;
        opacity: 1;
        padding: 0px 5px;
        top: 5px;
        right: 15px;
        left: auto;
      }
    }
  }
}