.topNav {
  .nav-link {
    color: $navbar-light-color;
  }
  @include media-breakpoint-down(lg) {
    .container {
      max-width: 100%;
    }
    margin-bottom: 0;
  }
}