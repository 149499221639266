
.ui {
  &.checkbox {
    label {
      line-height: 21px;
      padding-left: 2rem;
      &::before {
        width: 21px;
        height: 21px;
        border-radius: 6px;
      }
    }
    &.checked {
      label {
        position: relative;
        &::before {
          content: '';
          width: 13px;
          height: 13px;
          position: absolute;
          top: 4px;
          left: 4px;
          background-color: $gray-600 !important;
          border-radius: 4px;
          z-index: 100;
        }
        &::after {
          content: '';
          width: 21px;
          height: 21px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $white !important;
          border-radius: 6px;
          border: 1px solid $mid;
          z-index: 99;
        }
      }
      input[type="checkbox"] {
        
        
      }
    }
  }
}