// Responsive containers
.container-4x3 {
  height: 0;
  padding-top: 60%;
  width: 100%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//trying to come up with a container for inside application windows
.container-dashboard {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(lg) {
    width: 95%;
    max-width: 1280px;
  }
}

.container-full-width {
  .container-dashboard {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 100;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

// Background Utilities
.bg-image,
.bg-video,
.bg-iframe,
.hero,
.responsive-iframe {
  position: relative;
  display: block;
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $black;
  overflow: hidden;
  &.aspect-1x1 {
    padding-top: 100%;
    video {
      left: -25%;
      height: 100%;
      max-width: none;
      width: auto;
    }
  }
  &.aspect-2x1 {
    padding-top: 50%;
  }
  &.aspect-3x1 {
    padding-top: 33%;
  }
  &.aspect-4x1 {
    padding-top: 25%;
  }
  &.aspect-4x3 {
    padding-top: 75%;
  }
  &.aspect-5x7 {
    padding-top: 120%;
  }
  &.aspect-16x9 {
    padding-top: 56.25%;
  }
  &.aspect-auto {
    height: auto;
  }

  &.bg-contain {
    background-size: contain;
  }
  &.bg-cover {
    background-size: cover !important;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    z-index: 0;
  }
}

// Sticky behavior
.sticky {
  position: sticky;
  top: 0;
}

// Image utilities
.img-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
}
.img-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

// Text utilities
.text-nocase {
  text-transform: none !important;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  &.overlay-bottom {
    top: auto;
    bottom: 80px;
    height: auto;
    display: block;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.4);
  z-index: 10;
}

.overlay ~ div {
  z-index: 100;
}

.text-underline {
  text-decoration: underline !important;
}

.text-mega {
  font-size: 2rem;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
  }
}

// Button Utilities
.btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;
  text-align: center;
}

.btn-disabled {
  background-color: $gray-800;
  color: $white;
  cursor: not-allowed;
}

// Layout Utilities
.justify-self-start {
  justify-self: start !important;
}
.justify-self-end {
  justify-self: end !important;
}
.justify-self-center {
  justify-self: center !important;
}
.justify-self-stretch {
  justify-self: stretch !important;
}

// Adjust margin for fixed menu
.mt-menu {
  margin-top: 120px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-fill {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-full {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-half {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-three-quarters {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    height: 75vh;
  }
}
.bg-third {
  height: 33vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Responsive Cards
.responsive-card-deck {
  @include make-responsive-cards();
}

// Buttons
.btn-xl {
  line-height: 4rem;
  font-size: 2.25rem;
}

//ul checklist
.checklist {
  list-style-type: none;
  padding-left: 0;
  li {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    margin-bottom: 25px;
  }
  i {
    position: relative;
    display: inline-block;
    z-index: 10;
    font-size: 1.8125rem;
    margin-right: 5px;
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 1px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $white;
      border: 2px solid $gray-400;
      z-index: -1;
    }
  }
}

.split {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  .row {
    min-height: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .col-copy {
    padding-right: 0;
    padding-left: 0;
    z-index: 100;
    @include media-breakpoint-up(lg) {
      .content {
        max-width: 35vw;
      }
    }
    @include media-breakpoint-up(xl) {
      .content {
        max-width: 28vw;
        &.content-lg {
          max-width: 33vw;
        }
      }
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      // TODO: This may need to be refactored to make this content managed on some instances?
      &.accordion-nav {
        @include media-breakpoint-up(lg) {
          button {
            width: 50%;
          }
        }
      }
      li {
        margin-bottom: 15px;
      }
      a {
        background: transparent;
        display: block;
        line-height: 1.25;
        border: 0;
        color: $gray-800;
        width: 100%;
        font-family: $headings-font-family;
        font-size: 1.1rem;
        text-align: left;
        padding: 12px 0;
        border-bottom: 1px solid $gray-800;
        @include transition(0.5s);
        &:hover {
          color: $orange;
          border-bottom: 1px solid $orange;
        }
        &:active,
        &:focus {
          opacity: 0.7;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .col-asset {
    padding-right: 0;
    padding-left: 0;
    z-index: 100;
    .content {
      margin: 2rem 0;
    }
  }
  @include media-breakpoint-up(md) {
    // width: 100vw;
    padding: 0;
    &.slide-padded {
      padding: 3rem 0;
    }
    &.slide-full {
      height: 100vh;
      max-height: 100vw;
    }
    &.slide-square {
      max-height: 50vw;
    }
    .container {
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: calc(70vw);
      padding: 0;
      margin: auto;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      margin: auto;
    }
    .col-asset {
      .content {
        margin: 0;
        div {
          width: 100%;
        }
      }
    }
  }
}

.custom-tool-tip {
  position: absolute;
  top: -125px;
  left: 0;
  width: 100%;
  padding: 2rem 1rem;
  font-weight: 600;
  @include media-breakpoint-up(sm) {
    width: 85%;
  }
  @include media-breakpoint-up(xl) {
    top: -135px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 15%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $white;
  }
}

.dot {
  padding-left: 15px;
  position: relative;
  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 6px;
  }
  &.dot-need {
    &::before {
      background-color: $green;
    }
  }

  &.dot-have {
    &::before {
      background-color: $cyan;
    }
  }
}

.bg-gradient-md {
  @include media-breakpoint-down(lg) {
    display: block;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%);
      z-index: 100;
    }
    .container {
      z-index: 200;
      position: relative;
    }
  }
  
}
