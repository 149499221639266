html,
body {
  font-size: $font-size-base;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.38;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  @include transition();
}

h1,
.h1 {
  font-family: $font-family-serif;
  letter-spacing: 1px;
}

h2,
.h2 {
  font-family: $font-family-serif;
  font-weight: 600;
}

h4,
.h4
{
  letter-spacing: 0px;
}

.text-serif {
  font-family: $font-family-serif;
}

.text-sans-serif {
  font-family: $font-family-base;
}
