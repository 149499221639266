.filter-wrapper {
  background: $white;
  box-shadow: 1px 1px 3px rgba($black, 0.3);
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 105px;
    left: 0;
    width: 100%;
    padding: 1rem;
    
    &.active {
      left: 0;
      width: 100%;
      transform: none;
      .filters {
        display: inline-block;
      }
    }
  }
   
}

.filters {
  @include media-breakpoint-down(sm) {
    box-shadow: 1px 1px 3px rgba($black, 0.3);
    border-radius: $border-radius;
    padding: 0.6rem 1rem;
    display: block;
    background-color: $white;
  }
  .icon-close {
    font-size: 2rem;
    line-height: 1;
  }
}

.filter-trigger  {
  display: block;
  color: $body-color;
  background: $white;
  @include media-breakpoint-down(sm) {
    
    border-radius: $border-radius;
    
  }
  // .fa-chevron-up {
  //   @include transition(0.4s);
  //   transform: rotate(0)
  // }
  // &.active {
  //   padding: 0 0 1rem;
  // }

  
  @include media-breakpoint-up(lg) {
    display: none;
  }
}


.user-type-filters {
  display: block;
  &.active {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: block;
    &.active {
      display: block;
    }
  }
}