body {
  background-color: $body-bg;
  color: $body-color;
}

section {
  padding: 30px 0;
  width: 100%;
  @include media-breakpoint-up(md) {
    // padding: 60px 0 60px 75px;
    padding: 60px 0;
  }
}

dl {
  dd,
  dt {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

hr {
}

button {
  appearance: none;
  border: 0;
}
