.dropzone {
  cursor: pointer;
  .file-upload {
    border-radius: $border-radius;
    border: 1px dashed $dark;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.file-upload-wrapper {
  position: relative;
  .file-upload {
    position: absolute;
    top: 0;
    z-index: 95;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    @include transition(0.4s);
    &.empty {
      position: relative;
      opacity: 1;
    }
    &:hover {
      opacity: 1;
      color: $white !important;
      background-color: rgba($dark, 0.4);
    }
  }
}

.file-preview {
  position: relative;
  overflow: visible;
  box-shadow: 0 0 3px rgba($dark, 0.7);
  border-radius: 1rem;
  i {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: $warning;
    color: $white;
    border-radius: 50%;
    font-size: 1.5rem;
    box-shadow: 0 0 3px rgba($dark, 0.7);
    opacity: 0;
    cursor: pointer;
    z-index: 95;
    @include transition();
  }
  &:hover {
    i {
      opacity: 1;
      &:hover {
        background-color: $red;
      }
    }
  }
}
