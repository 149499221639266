.refreshMap {
  position: absolute;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  top: 6rem;
  @include media-breakpoint-up(md) {
    top: 2rem;
  }
  button {
    font-size: 1rem;
    color: $white;
    background-color: $danger;
    &:hover {
      // color: $dark;
    }
  }
}
